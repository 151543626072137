/*
 |-----------------------------------------------------------------------------
 | components/vendor/Aos/Aos.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import React, { useEffect } from 'react';

import * as IAos from './types';

import AOS from 'aos';

const Aos: React.FC<IAos.IProps> = ({ children }) => {
	useEffect(() => {
		AOS.init();
	}, []);

	return <>{children}</>;
};

export default Aos;
