/*
 |-----------------------------------------------------------------------------
 | components/layouts/Blocks/Blocks.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC } from 'react';

import { filter, get, isEmpty, map } from 'lodash';
import dynamic from 'next/dynamic';
// import { ray } from 'node-ray/web';

import { collectionSelector } from '@/features/collection';
import { pageSelector } from '@/features/page';
import { useAppSelector } from '@/app/hooks';

import * as IBlocks from './types';

const About = dynamic(() => import('@/organisms/About'));
const Alert = dynamic(() => import('@/molecules/Alert'));
const Articles = dynamic(() => import('@/organisms/Articles'));
const ArticlesLatest = dynamic(() => import('@/organisms/ArticlesLatest'));
const ArticlesOther = dynamic(() => import('@/organisms/ArticlesOther'));
const Banner = dynamic(() => import('@/organisms/Banner'));
const Body = dynamic(() => import('@/organisms/Body'));
const CallToAction = dynamic(() => import('@/organisms/CallToAction'));
const ContactInformation = dynamic(
	() => import('@/organisms/ContactInformation')
);
const Faqs = dynamic(() => import('@/organisms/Faqs'));
const Feature = dynamic(() => import('@/organisms/Feature'));
const Hero = dynamic(() => import('@/organisms/Hero'));
const People = dynamic(() => import('@/organisms/People'));
const Projects = dynamic(() => import('@/organisms/Projects'));
const Services = dynamic(() => import('@/organisms/Services'));

const Blocks: FC<IBlocks.IProps> = ({ blocks }) => {
	// ray('Debug layout Blocks:', {
	// 	blocks: blocks,
	// }).orange();

	const collection = useAppSelector(collectionSelector);
	const page = useAppSelector(pageSelector);

	if (isEmpty(blocks)) {
		return (
			<Alert
				isDismissible
				text={`No blocks have been added to this ${collection}`}
				variant="warning"
			/>
		);
	}

	return map(blocks, (block, index) => {
		switch (get(block, '__typename')) {
			case `${collection}BlocksAbout`:
				return (
					<About
						key={`block-${index}__${get(block, '__typename')}`}
						actions={get(block, 'actions')}
						image={get(block, 'image')}
						text={get(block, 'text')}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksArticles`:
				return (
					<Articles
						key={`block-${index}__${get(block, '__typename')}`}
						categories={get(block, 'categories')}
						text={get(block, 'text')}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksArticlesLatest`:
				return (
					<ArticlesLatest
						key={`block-${index}__${get(block, '__typename')}`}
						actions={get(block, 'actions')}
						text={get(block, 'text')}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksArticlesOther`:
				return null;
			// return (
			// 	<ArticlesOther
			// 		key={`block-${index}__${get(block, '__typename')}`}
			// 		actions={get(block, 'actions')}
			// 		limit={6}
			// 		text={get(block, 'text')}
			// 		title={get(block, 'title')}
			// 	/>
			// );
			case `${collection}BlocksBanner`:
				return (
					<Banner
						key={`block-${index}__${get(block, '__typename')}`}
						image={get(block, 'image') || get(page, 'image')}
						text={get(block, 'text')}
						title={get(block, 'title') || get(page, 'title')}
						variant={get(block, 'variant')}
					/>
				);
			case `${collection}BlocksBody`:
				return (
					<Body
						key={`block-${index}__${get(block, '__typename')}`}
						colour={get(block, 'colour')}
						columns={[
							...(get(block, 'column1')
								? [get(block, 'column1')]
								: []),
							...(get(block, 'column2')
								? [get(block, 'column2')]
								: []),
							...(get(block, 'column3')
								? [get(block, 'column3')]
								: []),
							...(get(block, 'column4')
								? [get(block, 'column4')]
								: []),
						]}
						icon={get(block, 'icon')}
						readingTime={get(block, 'readingTime')}
						sidenav={get(block, 'sidenav')}
						snap={get(block, 'snap')}
						socialShare={get(block, 'socialShare')}
					/>
				);
			case `${collection}BlocksCallToAction`:
				return (
					<CallToAction
						key={`block-${index}__${get(block, '__typename')}`}
						actions={get(block, 'actions')}
						image={get(block, 'image')}
						text={get(block, 'text')}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksCertifications`:
				return (
					<Alert
						isDismissible
						text={`The block ${get(
							block,
							'__typename'
						)} could not be found`}
						variant="error"
					/>
				);
			case `${collection}BlocksContactInformation`:
				return (
					<ContactInformation
						key={`block-${index}__${get(block, '__typename')}`}
						body={get(block, 'body')}
						form={get(block, 'form')}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksCountdown`:
				return null;
			case `${collection}BlocksDirectives`:
				return null;
			case `${collection}BlocksEvents`:
				return null;
			case `${collection}BlocksEventsLatest`:
				return null;
			case `${collection}BlocksEventsOther`:
				return (
					<Alert
						isDismissible
						text={`The block ${get(
							block,
							'__typename'
						)} could not be found`}
						variant="error"
					/>
				);
			case `${collection}BlocksFaqs`:
				return (
					<Faqs
						key={`block-${index}__${get(block, '__typename')}`}
						faqs={get(block, 'faqs')}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksFeature`:
				return (
					<Feature
						key={`block-${index}__${get(block, '__typename')}`}
						title={get(block, 'title')}
						variant={get(block, 'variant')}
					/>
				);
			case `${collection}BlocksFeed`:
				return null;
			case `${collection}BlocksFigures`:
				return null;
			case `${collection}BlocksGallery`:
				return (
					<Alert
						isDismissible
						text={`The block ${get(
							block,
							'__typename'
						)} could not be found`}
						variant="error"
					/>
				);
			case `${collection}BlocksHero`:
				return (
					<Hero
						key={`block-${index}__${get(block, '__typename')}`}
						banners={get(block, 'banners')}
					/>
				);
			case `${collection}BlocksJobs`:
				return null;
			case `${collection}BlocksJobsLatest`:
				return null;
			case `${collection}BlocksJobsOther`:
				return null;
			case `${collection}BlocksLocations`:
				return null;
			case `${collection}BlocksPatrons`:
				return (
					<Alert
						isDismissible
						text={`The block ${get(
							block,
							'__typename'
						)} could not be found`}
						variant="error"
					/>
				);
			case `${collection}BlocksPeople`:
				return (
					<People
						key={`block-${index}__${get(block, '__typename')}`}
						text={get(block, 'text')}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksPeopleFeatured`:
				return null;
			case `${collection}BlocksPosts`:
				return null;
			case `${collection}BlocksPostsLatest`:
				return null;
			case `${collection}BlocksPostsOther`:
				return null;
			case `${collection}BlocksPricingTable`:
				return null;
			case `${collection}BlocksProductInformation`:
				return null;
			case `${collection}BlocksProducts`:
				return null;
			case `${collection}BlocksProductsFeatured`:
				return null;
			case `${collection}BlocksProductsOther`:
				return (
					<Alert
						isDismissible
						text={`The block ${get(
							block,
							'__typename'
						)} could not be found`}
						variant="error"
					/>
				);
			case `${collection}BlocksProjects`:
				return (
					<Projects
						key={`block-${index}__${get(block, '__typename')}`}
						categories={get(block, 'categories')}
						text={get(block, 'text')}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksProjectsLatest`:
				return null;
			case `${collection}BlocksProjectsOther`:
				return null;
			case `${collection}BlocksRecipes`:
				return null;
			case `${collection}BlocksRecipesFeatured`:
				return null;
			case `${collection}BlocksRecipesOther`:
				return null;
			case `${collection}BlocksResults`:
				return null;
			case `${collection}BlocksReviews`:
				return null;
			case `${collection}BlocksReviewsLatest`:
				return (
					<Alert
						isDismissible
						text={`The block ${get(
							block,
							'__typename'
						)} could not be found`}
						variant="error"
					/>
				);
			case `${collection}BlocksServices`:
				return (
					<Services
						key={`block-${index}__${get(block, '__typename')}`}
						categories={get(block, 'categories')}
						text={get(block, 'text')}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksServicesFeatured`:
				return null;
			case `${collection}BlocksServicesOther`:
				return null;
			case `${collection}BlocksSocial`:
				return null;
			case `${collection}BlocksSpecialties`:
				return null;
			case `${collection}BlocksStockists`:
				return null;
			case `${collection}BlocksSubscriptions`:
				return null;
			case `${collection}BlocksTypes`:
				return null;
			case `${collection}BlocksUsps`:
				return null;
			case `${collection}BlocksValues`:
				return null;
			default:
				return null;
				// return (
				// 	<Alert
				// 		isDismissible
				// 		text={`The block ${get(
				// 			block,
				// 			'__typename'
				// 		)} could not be found`}
				// 		variant="error"
				// 	/>
				// );
		}
	});
};

export default Blocks;
